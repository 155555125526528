@mixin hide {
  display: none;
  opacity: 0;
}

.desktopMenu {
  position: relative;
}

.desktopMenu .popoverOverlay {
  @include hide;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: calc(100vh - var(--desktop-height));
  padding-top: 12px;
  cursor: default;
}

.desktopMenu .popoverOverlay.popoverOpen {
  display: block;
  opacity: 1;
  transition: opacity 0.1s ease-in-out;
  overflow: auto;
}

.desktopMenu .popoverContent {
  position: relative;
  background-color: #ffffff;
  border-radius: 8px;
  width: fit-content;
  margin: 0 0 0 150px;
  &.internal {
    margin-left: 250px;
  }
}

.desktopMenu .popoverArrow {
  @include hide;
  border-style: solid;
  border-width: 4px;
  position: absolute;
  border-color: transparent #fff #fff transparent;
  margin: 0;
  transform: rotateZ(-135deg);
  top: 8px;
  left: 380px;
  width: 8px;
  height: 8px;
  background: 0 0;
  box-sizing: border-box;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.1);
}

.desktopMenu .popoverArrow.popoverOpen {
  display: block;
  opacity: 1;
  transition: opacity 0.1s ease-in-out;
}
.popoverInnerContent {
  padding: 12px 0;
  box-shadow: 0 6px 6px 0 rgba(22, 45, 61, 0.06),
    0 0 18px 0 rgba(22, 45, 61, 0.12);
  border-radius: 8px;
}

.popoverInnerContent:focus-visible {
  outline: var(--focus) solid 2px;
}
