.nav {
  flex-direction: column;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 58px;
  z-index: 2;
  height: 52px;
  min-height: 52px;
  min-width: 500px;

  nav {
    background-color: #fff;
  }

  ul {
    display: flex;
    gap: 36px;
    list-style: none;
    padding: 0;
    margin-top: 18px;
    margin-bottom: 18px;
  }

  li {
    cursor: pointer;
    flex: 0 0 auto;
    margin-right: 3px;
    span {
      white-space: nowrap;
    }
  }
}

.navnoHeader {
  @extend .nav;
  top: 0px;
}

.scrollingWrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 0;
  margin: 0;
  width: 100%;
  border-bottom: 1px solid rgb(223, 229, 235);
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }

  ul {
    display: flex;
    gap: 36px;
    list-style: none;
    padding: 0;
    margin-bottom: 18px;

    @media (max-width: 724px) {
      margin-right: 24px;
      margin-top: 18px;
      gap: 24px;
    }
  }

  li {
    cursor: pointer;
    flex: 0 0 auto;
    margin-right: 3px;
    span {
      white-space: nowrap;
    }
  }
}

.active {
  text-decoration-line: underline !important;
  text-decoration-color: var(--wsr-color-B10, #116DFF) !important;
  text-decoration-thickness: 3px !important;
  text-underline-offset: 19px !important;
}
