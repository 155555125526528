.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 302px;
}

.stickyNav {
  position: sticky;
  top: 0px;
  z-index: 3;
  height: 78px;
}

.fixedLeftbar {
  position: fixed;
  top: 96px;
  width: 302px;
  min-width: 302px;
  max-width: 302px;
}
