.popoverInnerContent {
  padding: 36px 18px;
  box-shadow: 0 6px 6px 0 rgba(22, 45, 61, 0.06),
    0 0 18px 0 rgba(22, 45, 61, 0.12);
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.popoverInnerContent:focus-visible {
  outline: var(--focus) solid 2px;
}

.popoverInnerContent .divider {
  align-self: stretch;
  width: 1px;
  background-color: #dfe5eb;
}

.popoverInnerContent .col {
  align-self: stretch;
  width: calc(33% - 1px);
}

@media (min-width: 1280px) {
  .popoverInnerContent .col {
    width: calc(25% - 1px);
  }
}
