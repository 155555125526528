svg.noreviewsCount > path {
  fill: #000624;
  fill-opacity: 0.2;
}

.noReviews {
  width: max-content !important;
}

.reviewTextColor {
  color: #333853 !important;
}
