@mixin link {
  color: #000624;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

@mixin linkHover {
  background: #E7F0FF;
  color: #116DFF !important;
}

@mixin linkTextHover {
  color: #116DFF;
}

.servicesContainer .service:hover {
  @include linkHover;
}

.servicesContainer .service .serviceLink {
  @include link;
  text-decoration: none;
  display: block;
  padding: 6px 18px 6px;
}

.servicesContainer .service .serviceLink:hover {
  @include linkTextHover;
}
