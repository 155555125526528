#mph *::-webkit-scrollbar-thumb,
#mph-language-selector *::-webkit-scrollbar-thumb,
#mph-currency-selector *::-webkit-scrollbar-thumb {
  background-color: #acafc4 !important;
}

#mph *::-webkit-scrollbar-thumb:hover,
#mph-language-selector *::-webkit-scrollbar-thumb:hover,
#mph-currency-selector *::-webkit-scrollbar-thumb:hover {
  background-color: #868aa5 !important;
}

#mph *::-webkit-scrollbar-thumb:active,
#mph-language-selector *::-webkit-scrollbar-thumb:active,
#mph-currency-selector *::-webkit-scrollbar-thumb:active {
  background-color: #D6E6FE !important;
}

.marketplaceHeader.fixedTop {
  position: fixed;
  top: 0px;
}

.marketplaceHeader {
  box-sizing: border-box;
  font-family: Madefor;
  position: relative;
  background-color: #fff;
  height: var(--mobile-height);
  width: 100%;
  z-index: 4000;
  box-sizing: border-box;

  .container {
    position: relative;
    z-index: 2;
    height: 100%;
    display: flex;
    align-items: center;
    vertical-align: middle;
    box-shadow: 0px 0px 6px rgba(0, 6, 36, 0.12),
      0px 2px 4px rgba(0, 6, 36, 0.12);
  }

  .nav {
    height: 100%;
    width: 100%;
  }

  .navList {
    height: 100%;
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
  }

  .mobileMenu {
    z-index: 1;
    display: none;
    overflow: auto;
    background-color: #ffffff;
    position: fixed;
    height: calc(100vh - var(--mobile-height));
    width: 100vw;
    top: var(--mobile-height);
    padding: 18px 24px 132px;
    box-sizing: border-box;
  }

  .menuOpen {
    display: block;
  }

  .button:focus-visible {
    outline: var(--focus);
  }

  .a:focus-visible {
    outline: var(--focus);
  }

  .cell {
    height: 100%;
    margin-left: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .findAProfessionalLink.desktop {
    background-color: #000624;
    padding: 0 24px;
  }

  .findAProfessionalLink.mobile {
    padding: 0 22px;
  }

  .findAProfessionalLink .link {
    color: #ffffff;
  }

  .findAProfessionalLink .link:focus {
    outline-color: #ffffff;
  }

  .cell.right {
    margin-left: auto;
  }

  .cell.last {
    margin-right: 36px;
  }

  .cell.mobile.right {
    padding: 0 22px;
  }

  .mobileButton {
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    font-family: Madefor;
    color: #000624;
  }

  .mobileMenuStickBottom .topGradient {
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.9)
    );
    height: 50px;
  }

  .mobileMenuStickBottom .content {
    background-color: #fff;
    position: absolute;
    top: 50px;
    bottom: 0;
    width: 100%;
  }

  .mobileMenuStickBottom .content .divider {
    margin: 0 18px 30px;
  }

  .mobileMenuStickBottom .content .buttons {
    padding: 0 24px;
  }

  @media (min-width: 1000px) {
    height: var(--desktop-height);

    .mobile {
      display: none !important;
    }

    .cell.notLoggedIn {
      margin-left: 24px;
    }

    .cell {
      margin-left: 36px;
    }
  }

  @media (max-width: 999px) {
    .cell.desktop {
      display: none;
    }
  }
}
