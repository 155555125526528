.stickyHeader {
  display: flex;
  position: -webkit-sticky;
  flex-direction: column;
  position: sticky;
  background-color: #fff;
  top: -2px;
  z-index: 3;
  width: 100%;
}

.showShadow {
  box-shadow: 0px 0px 6px rgba(0, 6, 36, 0.12), 0px 2px 4px rgba(0, 6, 36, 0.12);
}

.navigationSection {
  outline: none;
  padding-bottom: 66px;
}
