.cell {
  height: 100%;
  margin-left: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.internal {
  color: #d0180b;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1px;
}

@media (min-width: 1000px) {
  .cell {
    margin-left: 36px;
  }
  .cell.notLoggedIn {
    margin-left: 24px;
  }
}
