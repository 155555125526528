.project {
  min-width: 276px;
  border-radius: 8px;
  &:hover {
    cursor: pointer;
    box-shadow: 0 7px 10px 2px rgba(0, 0, 0, 0.15),
      5px 5px 5px rgba(0, 0, 0, 0.1);
  }
}

.projectMobile {
  margin-top: 18px;
  flex: 0 0 auto;
  width: 80%;
  margin-right: 20px;

  &:hover {
    cursor: pointer;
    box-shadow: 0 7px 10px 2px rgba(0, 0, 0, 0.15),
      5px 5px 5px rgba(0, 0, 0, 0.1);
  }
}

.externalLinkIcon {
  color: '#F8F8F8';
  margin-right: '10px';
}

.viewWebsiteLink {
  text-decoration: none;
}
