.modal {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #fff;
  border-radius: 0 !important;
}

.modal__content {
  height: 100%;
  overflow-y: auto;
}

