.divider {
  background-color: #dfe5eb;
  margin: 18px 0;
}

.linkWrapper {
  padding: 12px 0;
}

.mobileMenuTextButton {
  width: 100%;
  justify-content: space-between;
}

.mobileMenuStickBottom .content .buttons {
  padding: 0 24px;
}

.mobileMenuStickBottom {
  position: fixed;
  bottom: 0;
  height: 148px;
  left: 0px;
  right: 0;
}
.mobileMenuStickBottom .topGradient {
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.9)
  );
  height: 50px;
}
.mobileMenuStickBottom .content {
  background-color: #fff;
  position: absolute;
  top: 50px;
  bottom: 0;
  width: 100%;
}
.mobileMenuStickBottom .content .divider {
  margin: 0 18px 30px;
}

@media (max-width: 999px) {
  .divider {
    height: 1px;
  }
}
