.reviewStar {
  width: 36px;
  height: 36px;
}
.reviewStarMobile {
  width: 24px;
  height: 24px;
}

.textColor {
  color: #868aa5 !important;
}
