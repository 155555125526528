.categoryRow {
  overflow: hidden;
  :hover {
    cursor: pointer;
    background-color: var(--wsr-color-B50, #E7F0FF);
  }
}

.selected {
  background-color: var(--wsr-color-B40, #D6E6FE);
  :hover {
    background-color: var(--wsr-color-B40, #D6E6FE);
  }
}

.headerLabel {
  margin: 0;
}

.scrollBar {
  overflow-x: scroll;
}

.scrollBar::-webkit-scrollbar:horizontal {
  height: 4px;
}

.scrollBar::-webkit-scrollbar-thumb:horizontal {
  background: #acafc4;
  border-radius: 8px;
}

.scrollBar::-webkit-scrollbar-thumb:horizontal:hover {
  background: #868aa5;
}

.scrollBar::-webkit-scrollbar-thumb:horizontal:active {
  background: #595d70;
}
