.projects {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(calc(max(278px, 100% / 4)), 1fr)
  );
  gap: 24px;
  margin-top: 24px;
}

.projectsMobile {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 0;
  margin: 0;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
}
