.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 302px;
}

.divider {
  position: relative;
  height: 1px;
  z-index: 3;
}

.stickyNav {
  transition: all 0.2s;
  opacity: 0;
  position: sticky;
  top: 0px;
  z-index: 3;
  height: 78px;
  width: 100vw;
}

.text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap !important;
  max-width: 148px;
}
