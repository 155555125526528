.accordionContainer [data-hook='header'] {
  background-color: #fff !important;
}

.accordionContainer [data-hook='children'] {
  padding-bottom: 0;
}

.categoryLink {
  width: 100%;
}
