@mixin link {
  color: #000624;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

@mixin linkHover {
  background: #E7F0FF;
  color: #116DFF !important;
}

@mixin linkTextHover {
  color: #116DFF;
}

@mixin categoryLink {
  color: #868aa5;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
}

.categoryContainer {
  padding: 0 18px 24px 12px;
}

.categoryContainer .category {
  margin-top: 6px;
}

.categoryContainer .category:hover {
  @include linkHover;
}

.categoryContainer .category .categoryLink {
  @include categoryLink;
  text-decoration: none;
  display: block;
  padding: 6px 0 6px 18px;
}

.categoryContainer .category .categoryLink:hover {
  @include linkTextHover;
}

.categoryContainer .servicesContainer .service:hover {
  @include linkHover;
}

.categoryContainer .servicesContainer .service .serviceLink {
  @include link;
  text-decoration: none;
  display: block;
  padding: 6px 18px 6px;
}
