.modal {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #fff;
  border-radius: 0 !important;
  overflow: hidden;
}

.modal__content {
  height: fit-content;
  overflow-y: auto;
  padding: 0px 24px;
  min-height: 300px;
}

.modal__content input:disabled {
  color: rgba(0, 6, 36, 0.3) !important;
}

.filterRow:hover {
  cursor: pointer;
  background-color: rgba(237, 162, 0, 0.15);
}

.grayText {
  color: #595d70 !important;
}
