@mixin linkHover {
  background: #E7F0FF;
  color: #116DFF !important;
}

@mixin linkTextHover {
  color: #116DFF;
}

.loggedinPopoverMenu{
  height: 100%;
  vertical-align: middle;
  display: flex;
}

.cell {
  height: 100%;
  margin-left: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cell.right {
  margin-left: auto;
}

.cell.last {
  margin-right: 36px;
}

.menuLink:hover {
  @include linkHover;
  width: 100%;
  border-radius: 0 !important;
}
.menuLink {
  color: #000624 !important;
  padding: 6px 30px !important;
  width: 100%;
  height: auto !important;
}
.btnDarkSecondary {
  border-color: #000624 !important;
}
.btnDarkSecondary:hover {
  background-color: #000624 !important;
}
.btnDarkPrimary {
  border-color: #000624 !important;
  background-color: #000624 !important;
}
.btnDarkPrimary:hover {
  background-color: #32536A !important;
  border-color: #32536A !important;
}

@media (min-width: 1000px) {
  .cell.notLoggedIn {
    margin-left: 24px;
  }
  .cell {
    margin-left: 36px;
  }
}

@media (max-width: 999px) {
  .cell.desktop {
    display: none;
  }
}
